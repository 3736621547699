import React, { useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { Pane } from 'evergreen-ui';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { USER_STATES } from '../Context/States';
import { useAuthContext } from '../Context/Auth';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser, setLastDecline, userState } = useAuthContext();
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderComponent = routeProps => {
    if (
      userState === USER_STATES.NO_COMPANY &&
      history.location.pathname !== '/company-registration'
    ) {
      history.push('/company-registration');
    }

    if (userState === USER_STATES.NOT_VERIFIED && history.location.pathname !== '/verify') {
      history.push('/verify');
    }

    if (
      userState === USER_STATES.SIGNED_IN &&
      ['/company-registration', '/verify'].includes(history.location.pathname)
    ) {
      history.push('/');
    }

    if (currentUser) {
      return (
        <Pane width="100vw" height="100vh" display="grid" gridTemplateRows="8% auto">
          <Navbar toggleCollapsed={toggleCollapsed} isCollapsed={isCollapsed} />
          <Pane
            display="grid"
            gridTemplateColumns={isCollapsed ? '50px auto' : '20% auto'}
            zIndex={3}
            height="92vh"
          >
            <Sidebar isCollapsed={isCollapsed} />
            <Pane zIndex={0} height="100%" width="100%" overflowY="scroll" background="tint1">
              <RouteComponent {...routeProps} />
            </Pane>
          </Pane>
        </Pane>
      );
    }

    // Set last declined path and redirect to sign in page
    setLastDecline(history.location.pathname);
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default PrivateRoute;
