import { Icon, Pane, Position, Text, Tooltip } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { useHover } from '../Helpers/useHover';

const SidebarButton = ({ path, onClick, label, icon, isCollapsed, isDanger }) => {
  const [refHover, isHover] = useHover();
  const [refHoverCollapsed, isHoverCollapsed] = useHover();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (path === '/' && window.location.pathname !== '/') {
      setIsActive(false);
      return;
    }
    setIsActive(window.location.pathname.startsWith(path));
  }, [window.location.pathname]);

  const getBgColor = () => {
    if (isActive) return '#47B881';
    if (isHover || isHoverCollapsed) return '#EDF0F2';
    return 'white';
  };

  const getTextColor = () => {
    if (isDanger) return '#BF0E08';
    if (isActive) return 'white';
    return '#234361';
  };

  return (
    <Pane>
      <Pane display={isCollapsed ? 'default' : 'none'} ref={refHoverCollapsed}>
        <Tooltip content={label} position={Position.RIGHT} showDelay={300}>
          <Pane
            padding={8}
            paddingLeft={12}
            display="flex"
            alignItems="center"
            width="100%"
            marginY={4}
            onClick={onClick}
            backgroundColor={getBgColor()}
            borderBottomLeftRadius={4}
            borderTopLeftRadius={4}
            cursor="pointer"
          >
            <Icon icon={icon} size={12} color={getTextColor()} />
          </Pane>
        </Tooltip>
      </Pane>

      <Pane display={isCollapsed ? 'none' : 'default'}>
        <Pane
          paddingY={8}
          paddingX={16}
          display="flex"
          alignItems="center"
          width="100%"
          minWidth="200px"
          marginY={4}
          onClick={onClick}
          backgroundColor={getBgColor()}
          borderRadius={4}
          cursor="pointer"
          ref={refHover}
        >
          <Icon icon={icon} size={12} color={getTextColor()} />
          <Text size={300} marginLeft={8} color={getTextColor()}>
            {label}
          </Text>
        </Pane>
      </Pane>
    </Pane>
  );
};

/*
          {isCollapsed ? (
            <Tooltip content="Settings" position={Position.RIGHT} showDelay={300}>
              <IconButton
                icon={CogIcon}
                appearance="minimal"
                marginBottom={8}
                onClick={() => history.push('/settings/account')}
              />
            </Tooltip>
          ) : (
            <Button
              iconBefore={CogIcon}
              marginBottom={8}
              onClick={() => history.push('/settings/account')}
              appearance="minimal"
              width="100%"
              minWidth="200px"
            >
              Settings
            </Button>
          )}



*/

export default SidebarButton;
