import React, { useContext, createContext, useCallback, useState, useEffect } from 'react';
import { statusArchived } from '../Constants/JobStatus';
import { get } from '../Helpers/fetcher';

const JobContext = createContext();
const useJobContext = () => useContext(JobContext);

const JobProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const resp = await get('/jobs');
    if (resp.ok) {
      setData(resp.data);
      setActiveJobs(resp.data.filter(item => item.status !== statusArchived));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <JobContext.Provider value={{ refetchData: fetchData, loading, data, activeJobs }}>
      {children}
    </JobContext.Provider>
  );
};

export { JobProvider, useJobContext };
