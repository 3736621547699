import { defaultTheme } from 'evergreen-ui';

const ijoTheme = {
  ...defaultTheme,
};

ijoTheme.colors.text = {
  danger: '#bf0e08',
  dark: '#234361',
  default: '#425A70',
  info: '#084b8a',
  muted: '#66788A',
  selected: '#1070ca',
  success: '#00783e',
  warning: '#95591e',
};

export default ijoTheme;
