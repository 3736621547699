import React from 'react';
import { IconButton, MenuClosedIcon, MenuOpenIcon, Pane } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import Logo from '../Assets/logo.png';

const Navbar = ({ toggleCollapsed, isCollapsed }) => {
  return (
    <Pane elevation={1} width="100%" height="8vh" zIndex={4}>
      <Pane
        display="flex"
        height="100%"
        alignItems="center"
        justifyContent="flex-start"
        gridArea="leftLogo"
      >
        <IconButton
          icon={isCollapsed ? MenuOpenIcon : MenuClosedIcon}
          appearance="minimal"
          onClick={toggleCollapsed}
          marginRight={24}
          marginLeft={8}
        />
        <Link to="/" className="Link">
          <img src={Logo} alt="" style={{ transform: 'translateY(1.5px)', height: '24px' }} />
        </Link>
      </Pane>
    </Pane>
  );
};

export default Navbar;
