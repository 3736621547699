export const statusAccepting = 'accepting';
export const statusInactive = 'inactive';
export const statusArchived = 'archived';

export const tooltipAccepting = 'Accepting Applicants';
export const tooltipInactive = 'Unpublished';
export const tooltipArchived = 'Archived';

export const colorAccepting = '#47b881'; // green
export const colorInactive = '#F7D154'; // yellow
export const colorArchived = '#C7CED4'; // grey
