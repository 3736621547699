export const USER_STATES = {
  SIGNED_IN: 'SIGNED_IN',
  NO_COMPANY: 'NO_COMPANY',
  NOT_VERIFIED: 'NOT_VERIFIED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export const ERROR_CODES = {
  NO_COMPANY: 'user/no-company',
  NOT_VERIFIED: 'user/email-not-verified',
  ALREADY_REGISTERED: 'user/already-registered',
};
