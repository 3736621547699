import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import './App.css';
import { ThemeProvider } from 'evergreen-ui';
import ijoTheme from './Constants/Theme';
import { AuthProvider } from './Context/Auth';
import { JobProvider } from './Context/Job';
import PrivateRoute from './Components/PrivateRoute';

const ATS = loadable(() => import(/* webpackChunkName: "ATS" */ './Routes/ATS'));
const Scheduler = loadable(() => import(/* webpackChunkName: "Scheduler" */ './Routes/Scheduler'));
const Picker = loadable(() => import(/* webpackChunkName: "Picker" */ './Routes/Picker'));
const Login = loadable(() => import(/* webpackChunkName: "Login" */ './Routes/Login'));
const Signup = loadable(() => import(/* webpackChunkName: "Signup" */ './Routes/Signup'));
const Applicant = loadable(() => import(/* webpackChunkName: "Applicant" */ './Routes/Applicant'));
const Dashboard = loadable(() => import(/* webpackChunkName: "Dashboard" */ './Routes/Dashboard'));
const Job = loadable(() => import(/* webpackChunkName: "Job" */ './Routes/Job'));
const CreateJob = loadable(() => import(/* webpackChunkName: "CreateJob" */ './Routes/CreateJob'));
const Calendar = loadable(() => import(/* webpackChunkName: "Calendar" */ './Routes/Calendar'));
const CompanyRegistration = loadable(() =>
  import(/* webpackChunkName: "CompanyRegistration" */ './Routes/CompanyRegistration'),
);
const EditJob = loadable(() => import(/* webpackChunkName: "EditJob" */ './Routes/EditJob'));
const CXO = loadable(() => import(/* webpackChunkName: "CXO" */ './Routes/CXO'));
const Settings = loadable(() => import(/* webpackChunkName: "Settings" */ './Routes/Settings'));
const Verify = loadable(() => import(/* webpackChunkName: "Verify" */ './Routes/Verify'));
const Error404 = loadable(() => import(/* webpackChunkName: "Error404" */ './Routes/Error404'));
const ResetPassword = loadable(() =>
  import(/* webpackChunkName: "ResetPassword" */ './Routes/ResetPassword'),
);

const App = () => {
  return (
    <div className="App">
      <ThemeProvider value={ijoTheme}>
        <AuthProvider>
          <JobProvider>
            <Router>
              <Switch>
                <PrivateRoute exact path="/ats" component={ATS} />
                <PrivateRoute exact path="/" component={Dashboard} />
                <PrivateRoute path="/createjob" component={CreateJob} />
                <PrivateRoute path="/picker/:uuid" component={Picker} />
                <PrivateRoute path="/applicant/:applicantId" component={Applicant} />
                <PrivateRoute path="/company-registration" component={CompanyRegistration} />
                <PrivateRoute path="/calendar" component={Calendar} />
                <PrivateRoute path="/editjob/:jobid/:type" component={EditJob} />
                <PrivateRoute path="/settings/:type" component={Settings} />
                <PrivateRoute path="/verify" component={Verify} />
                <Route path="/login" component={Login} />
                <Route path="/signup" component={Signup} />
                <Route path="/reset" component={ResetPassword} />
                <Route path="/scheduler/:applicantId" component={Scheduler} />
                <Route path="/job" component={Job} />
                <Route path="/cxo/careers" component={CXO} />
                <Route path="*">
                  <Error404 />
                </Route>
              </Switch>
            </Router>
          </JobProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
