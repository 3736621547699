import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
const app = firebase.initializeApp(FIREBASE_CONFIG);

export const storage = app.storage();
export const analytics = app.analytics();

export default app;
