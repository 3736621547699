import React, { useEffect, useState, useContext } from 'react';
import { Pane, Spinner } from 'evergreen-ui';
import { get } from '../Helpers/fetcher';
import firebase from '../Firebase';
import { USER_STATES } from './States';

const AuthContext = React.createContext();
const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState('');
  const [pending, setPending] = useState(true);
  const [lastDecline, setLastDecline] = useState('');
  const [userState, setUserState] = useState('NOT_SIGNED_IN');

  const getUserDetails = async () => {
    const res = await get(`/accounts/details`);
    if (res.ok) {
      if (!res.data.user.emailVerified) {
        setUserState(USER_STATES.NOT_VERIFIED);
      } else if (!res.data.company) {
        setUserState(USER_STATES.NO_COMPANY);
      } else {
        setUserState(USER_STATES.SIGNED_IN);
      }
      setCurrentUser(res.data);
    } else {
      console.error('current res error:', res);
      setUserState(USER_STATES.UNKNOWN_ERROR);

      setCurrentUser(null);
    }
    setPending(false);
  };

  const refetchUser = () => {
    getUserDetails();
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async user => {
      setPending(true);
      const currentToken = (await user?.getIdToken()) || null;
      if (currentToken) {
        setToken(currentToken);
        getUserDetails(currentToken);
      } else {
        setPending(false);
      }
    });
  }, []);

  if (pending) {
    return (
      <Pane width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
        <Spinner />
      </Pane>
    );
  }

  return (
    <AuthContext.Provider
      value={{ currentUser, token, lastDecline, setLastDecline, refetch: refetchUser, userState }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuthContext };
