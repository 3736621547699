import React, { useEffect, useCallback } from 'react';
import {
  Pane,
  Text,
  Heading,
  Avatar,
  LogOutIcon,
  GlobeNetworkIcon,
  ApplicationIcon,
  CogIcon,
  ClipboardIcon,
  BriefcaseIcon,
} from 'evergreen-ui';
import { parse } from 'query-string';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from '../Context/Auth';
import { DOMAIN_NAME } from '../Constants';
import { post } from '../Helpers/fetcher';
import app, { analytics } from '../Firebase';
import SidebarButton from './SidebarButton';

const Sidebar = ({ isCollapsed }) => {
  const { token, currentUser, refetch } = useAuthContext();
  const history = useHistory();

  const sendAnalytics = eventName => {
    analytics.logEvent(eventName, {
      companyName: currentUser.company.name,
    });
  };

  const logout = async () => {
    await app.auth().signOut();
    window.location.reload();
  };

  const sendCodeToServerAndAddCalendar = useCallback(
    async code => {
      const redirectUrl = `${DOMAIN_NAME}/`;
      const requestPayload = {
        code,
        redirect_url: redirectUrl,
        token,
      };

      await post('/oauth2/auth', requestPayload);
      refetch(token);

      // Add calendar to user
      await post('/calendar');
    },
    [token, refetch],
  );

  useEffect(() => {
    if (!currentUser?.authProviders.google) {
      const query = parse(window.location.search);
      if (query?.code) {
        sendCodeToServerAndAddCalendar(query.code);
        history.replace('/');
      }
    }
  }, [sendCodeToServerAndAddCalendar, currentUser, history]);

  return (
    <Pane
      elevation={2}
      paddingY={32}
      paddingX={isCollapsed ? 0 : 32}
      zIndex={2}
      overflowX="hidden"
      width={isCollapsed ? '50px' : '20vw'}
      transitionDuration="100ms"
    >
      <Pane
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems={isCollapsed ? 'center' : 'start'}
        width="100%"
      >
        <Pane marginBottom={8}>
          <Avatar name={currentUser.user?.displayName} size={isCollapsed ? 30 : 80} />
        </Pane>
        <Pane borderBottom="muted" width="100%">
          {!isCollapsed && (
            <Pane display="flex" flexDirection="column" marginBottom={8}>
              <Heading>{!isCollapsed && currentUser.user?.displayName}</Heading>
              <Text>{!isCollapsed && currentUser.user?.email}</Text>
              <Text>{!isCollapsed && currentUser.company?.name}</Text>
            </Pane>
          )}
        </Pane>

        <Pane
          marginTop={8}
          borderBottom="muted"
          display="flex"
          flexDirection="column"
          width="100%"
          paddingLeft={isCollapsed ? 8 : 0}
        >
          <SidebarButton
            icon={ClipboardIcon}
            label="Create Job"
            path="/createjob"
            onClick={() => {
              sendAnalytics('create_job_sidebar');
              history.push('/createjob');
            }}
            isCollapsed={isCollapsed}
          />
          <SidebarButton
            icon={ApplicationIcon}
            label="Applicant Tracker"
            path="/ats"
            onClick={() => {
              sendAnalytics('applicant_tracker_sidebar');
              history.push('/ats?stage=New');
            }}
            isCollapsed={isCollapsed}
          />
          <SidebarButton
            icon={GlobeNetworkIcon}
            label="View Company Portal"
            path="/job"
            onClick={() => {
              sendAnalytics('company_portal_sidebar');
              window.open(`${DOMAIN_NAME}/job/${currentUser.company.slugUrl}`, '_blank');
            }}
            isCollapsed={isCollapsed}
          />
          <SidebarButton
            icon={BriefcaseIcon}
            label="Jobs"
            path="/"
            onClick={() => {
              sendAnalytics('jobs_sidebar');
              history.push('/');
            }}
            isCollapsed={isCollapsed}
          />
          <SidebarButton
            icon={CogIcon}
            label="Settings"
            path="/settings"
            onClick={() => {
              sendAnalytics('setting_sidebar');
              history.push('/settings/account');
            }}
            isCollapsed={isCollapsed}
          />
        </Pane>
        <Pane
          marginTop={8}
          display="flex"
          flexDirection="column"
          width="100%"
          paddingLeft={isCollapsed ? 8 : 0}
        >
          <SidebarButton
            icon={LogOutIcon}
            label="Log Out"
            path="/login"
            onClick={logout}
            isCollapsed={isCollapsed}
            isDanger
          />
        </Pane>
      </Pane>
    </Pane>
  );
};

export default Sidebar;
