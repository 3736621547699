import axios from 'axios';
import firebase from '../Firebase';
import { API_ENDPOINT } from '../Constants';

const getToken = async (forceRefresh = false) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return null;
  }
  return user.getIdToken(forceRefresh);
};

const defaultHeaders = async () => {
  const token = await getToken();
  return {
    authorization: `Bearer ${token}`,
  };
};

const appendWithBaseURL = url => {
  return `${API_ENDPOINT}${url}`;
};

const handleError = error => {
  if (error.response) {
    return { statusCode: error.response.status, data: error.response?.data || {}, ok: false };
  }
  console.error(error.message);
  return { ok: false };
};

const handleSuccess = response => {
  return { statusCode: response.status, data: response?.data || {}, ok: true };
};

const post = async (url, payload = {}, isPublic) => {
  let initHeader = {};
  if (!isPublic) {
    initHeader = await defaultHeaders();
  }
  try {
    const response = await axios.post(url, payload, {
      headers: {
        ...initHeader,
      },
    });
    return handleSuccess(response);
  } catch (error) {
    return handleError(error);
  }
};

const patch = async (url, payload = {}, isPublic) => {
  let initHeader = {};
  if (!isPublic) {
    initHeader = await defaultHeaders();
  }
  try {
    const response = await axios.patch(url, payload, {
      headers: {
        ...initHeader,
      },
    });
    return handleSuccess(response);
  } catch (error) {
    return handleError(error);
  }
};

const get = async (url, isPublic) => {
  let initHeader = {};
  if (!isPublic) {
    initHeader = await defaultHeaders();
  }

  try {
    const response = await axios.get(url, {
      headers: {
        ...initHeader,
      },
    });
    return handleSuccess(response);
  } catch (error) {
    return handleError(error);
  }
};

const postWithPiperURL = (path, payload = {}) => {
  return post(appendWithBaseURL(path), payload, false);
};

const getWithPiperURL = path => {
  return get(appendWithBaseURL(path), false);
};

const patchWithPiperURL = (path, payload = {}) => {
  return patch(appendWithBaseURL(path), payload, false);
};

const postPubliclyWithPiperURL = (path, payload = {}) => {
  return post(appendWithBaseURL(path), payload, true);
};

const getPubliclyWithPiperURL = path => {
  return get(appendWithBaseURL(path), true);
};

const patchPubliclyWithPiperURL = (path, payload = {}) => {
  return patch(appendWithBaseURL(path), payload, true);
};

export {
  getWithPiperURL as get,
  patchWithPiperURL as patch,
  postWithPiperURL as post,
  getPubliclyWithPiperURL as getPublic,
  postPubliclyWithPiperURL as postPublic,
  patchPubliclyWithPiperURL as patchPublic,
};
