const API_ENDPOINT = process.env.REACT_APP_BACKEND_API;
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;
const SCHEDULER_STATUS_INIT = 'Schedule Not Set';
const SCHEDULER_STATUS_AWAITING_APPLICANT = "Awaiting Applicant's Availability";
const SCHEDULER_STATUS_AWAITING_INTERVIEWER = "Awaiting Interviewer's Date";
const SCHEDULER_STATUS_SCHEDULE_SET = 'Schedule Set';
const SCHEDULER_STATUS_FEEDBACK_RECEIVED = 'Feedback Received';
const DEFAULT_PUBLIC_LOGO =
  'https://firebasestorage.googleapis.com/v0/b/powerful-vertex-275315.appspot.com/o/logo%2FPiper%20Company%20Default%20Icon.png?alt=media&token=c116912e-721c-4fed-a95d-5b4e243e6623';
const DEFAULT_PUBLIC_BANNER =
  'https://firebasestorage.googleapis.com/v0/b/powerful-vertex-275315.appspot.com/o/logo%2FPiper%20Default%20BG%20company.jpeg?alt=media&token=5fdf0c0c-78bb-42d4-951b-b822dff2789b';

const SUPER_ADMIN = 'super-admin';

export {
  API_ENDPOINT,
  DOMAIN_NAME,
  SCHEDULER_STATUS_INIT,
  SCHEDULER_STATUS_AWAITING_APPLICANT,
  SCHEDULER_STATUS_AWAITING_INTERVIEWER,
  SCHEDULER_STATUS_SCHEDULE_SET,
  SCHEDULER_STATUS_FEEDBACK_RECEIVED,
  DEFAULT_PUBLIC_BANNER,
  DEFAULT_PUBLIC_LOGO,
  SUPER_ADMIN,
};
